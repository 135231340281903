import React, { Component } from 'react';
import './UnderConstructionPage.scss';
import UnderConstructionAnimation from '../global/UnderConstructionAnimation';
import logo from '../../../assets/images/distudio_svg.svg';
import { CSSTransition } from 'react-transition-group';
class UnderConstructionPage extends Component {
    render() {
        return (React.createElement("div", { className: 'UnderConstructionPage row g-0' },
            React.createElement("div", { className: 'col-12 col-lg-6 uc-left-side g-0' },
                React.createElement("div", { className: 'logo-container' },
                    React.createElement("img", { className: 'top-logo', src: logo }),
                    React.createElement("div", { className: 'logo-name' }, "Distudio")),
                React.createElement("div", { className: 'animation-container' },
                    React.createElement(UnderConstructionAnimation, null)),
                React.createElement(CSSTransition, { classNames: "enter-scene-animation", in: true, appear: true, timeout: 2000 },
                    React.createElement("div", { style: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' } },
                        React.createElement("div", { className: 'working-hard-title', style: { fontVariant: 'small-caps' } }, "Under construction"),
                        React.createElement(CSSTransition, { classNames: "enter-scene-animation-long", in: true, appear: true, timeout: 2000 },
                            React.createElement("div", { className: 'working-hard-description' },
                                "We're putting all the pieces together.",
                                React.createElement("br", null),
                                "Coming soon.")))))));
    }
}
export default UnderConstructionPage;
